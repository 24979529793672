// FetchPost.js

import { extractTokenDetails } from '../tokenInfo';
import { API_KEY } from '../../constants';
import { API_BASE_URL } from '../../config';

export const fetchTokenInfo = async (tokenAddress) => {
    try {
        const response = await fetch(`${API_BASE_URL}/tokens/${tokenAddress}`, {
            headers: {
                'Content-Type': 'application/json',
                'X-API-Key': API_KEY
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error(`Error fetching token info for ${tokenAddress}:`, error);
        return null;
    }
};

export const fetchAllPosts = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/posts`, {
            headers: {
                'Content-Type': 'application/json',
                'X-API-Key': API_KEY
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const posts = await response.json();
        const tokenInfoPromises = posts.map(post =>
            post.token_address ? fetchTokenInfo(post.token_address) : null
        );
        const tokenInfoResults = await Promise.all(tokenInfoPromises);
        const tokenInfo = {};
        tokenInfoResults.forEach((info, index) => {
            if (info && posts[index].token_address) {
                tokenInfo[posts[index].token_address] = info;
            }
        });
        return { posts, tokenInfo };
    } catch (error) {
        console.error('Error fetching posts:', error);
        throw error;
    }
};

export const fetchPostDetails = async (postId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/posts/detail/${postId}`, {
            headers: {
                'X-API-Key': API_KEY
            }
        });
        if (!response.ok) {
            throw new Error('Failed to fetch post details');
        }
        const post = await response.json();
        console.log('Fetched post details:', post);  // Add this line for debugging
        
        // Restructure comments to create a nested hierarchy
        const commentMap = {};
        post.comments.forEach(comment => {
            comment.replies = [];
            commentMap[comment.id] = comment;
        });
        
        const nestedComments = [];
        post.comments.forEach(comment => {
            if (comment.parent_comment_id) {
                const parent = commentMap[comment.parent_comment_id];
                if (parent) {
                    parent.replies.push(comment);
                }
            } else {
                nestedComments.push(comment);
            }
        });
        
        post.comments = nestedComments;
        return { post };
    } catch (error) {
        console.error('Error fetching post details:', error);
        throw error;
    }
};
