import { Address } from '@ton/ton';

const API_ENDPOINT = 'https://toncenter.com/api/v2/jsonRPC';

async function fetchBalance(address) {
    const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            jsonrpc: '2.0',
            id: 1,
            method: 'getAddressBalance',
            params: { address: address.toString() }
        }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.result;
}

export const fetchUserTokens = async (walletAddress) => {
    try {
        const address = Address.parse(walletAddress);
        const balance = await fetchBalance(address);

        // Fetch native TON balance
        const tokens = [
            { address: 'native', symbol: 'TON', balance: balance },
        ];

        // For now, we'll skip fetching Jetton balances
        // In a real-world scenario, you'd implement a way to fetch Jetton balances here

        return tokens;
    } catch (error) {
        console.error('Error fetching user tokens:', error);
        throw error;
    }
};

// Remove or comment out the fetchJettonMinters function for now
// async function fetchJettonMinters(ownerAddress) {
//     // Implementation removed
// }
