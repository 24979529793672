import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { WalletProvider } from './contexts/WalletContext';
import { ThemeProvider } from './contexts/ThemeContext';
import Header from './components/navigation/header';
import Footer from './components/navigation/footer';
import Home from './components/pages/Home';
import TokenPage from './components/pages/TokenPage';
import BrowseAll from './components/pages/BrowseAll';
import PostDetails from './components/pages/PostDetails';
import UserProfile from './components/pages/UserProfile';
import TradePage from './components/pages/TradePage';
import { ScrollToTop } from './utils/UIX/ScrollUtils';
import RoadmapPage from './components/pages/RoadmapPage';

const manifestUrl = process.env.REACT_APP_MANIFEST_URL;

function App() {
  return (
    <TonConnectUIProvider manifestUrl={manifestUrl}>
      <WalletProvider>
        <ThemeProvider>
          <Router>
            <ScrollToTop />
            <div className="App flex flex-col min-h-screen">
              <Header />
              <main className="flex-grow pt-16 pb-[60px]">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/tokenpage/:address" element={<TokenPage />} />
                  <Route path="/tokenpage/ton" element={<TokenPage />} />
                  <Route path="/browse" element={<BrowseAll />} />
                  <Route path="/post/:postId" element={<PostDetails />} />
                  <Route path="/user/:walletAddress" element={<UserProfile />} />
                  <Route path="/trade" element={<TradePage />} />
                  <Route path="/roadmap" element={<RoadmapPage />} />
                </Routes>
              </main>
              <Footer />
            </div>
          </Router>
        </ThemeProvider>
      </WalletProvider>
    </TonConnectUIProvider>
  );
}

export default App;
