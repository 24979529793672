import { TonClient } from '@ton/ton';

const tonClient = new TonClient({
    endpoint: 'https://toncenter.com/api/v2/jsonRPC', // Replace with your preferred endpoint
});

export const sendTransaction = async (wallet, transaction) => {
    try {
        // Here, we're assuming the wallet object has a sendTransaction method
        // You might need to adjust this based on the actual structure of your wallet object
        const result = await wallet.sendTransaction(transaction);
        console.log('Transaction sent:', result);
        return result;
    } catch (error) {
        console.error('Error sending transaction:', error);
        throw error;
    }
};

export default tonClient;
