import React, { useState, useCallback, useEffect } from 'react';
import { fetchPool } from './fetch/fetchPool';
import { performSwap } from './services/performSwap';
import { useWallet } from '../contexts/WalletContext';
import { FaExchangeAlt } from 'react-icons/fa';
import { checkTransactionStatus } from './services/checkTransactionStatus';
import { fetchTokenPairData } from './services/services_api/dexScreenerService';
import { useTheme } from '../contexts/ThemeContext';

const SwapComponent = ({ currentTokenAddress, tokenSymbol = 'Token' }) => {
    const [amount, setAmount] = useState('');
    const [mode, setMode] = useState('buy');
    const [pool, setPool] = useState(null);
    const { wallet, sendTransaction, isWalletConnected } = useWallet();
    const [isLoading, setIsLoading] = useState(false);
    const [transactionStatus, setTransactionStatus] = useState(null);
    const [pairData, setPairData] = useState(null);
    const [usdValue, setUsdValue] = useState(null);
    const { isDarkMode } = useTheme();

    useEffect(() => {
        const fetchPoolAndPairData = async () => {
            try {
                if (currentTokenAddress) {
                    const poolAddress = await fetchPool(currentTokenAddress);
                    setPool(poolAddress);
                    const dexScreenerData = await fetchTokenPairData(currentTokenAddress);
                    if (dexScreenerData.pairs && dexScreenerData.pairs.length > 0) {
                        setPairData(dexScreenerData.pairs[0]);
                    }
                }
            } catch (error) {
                console.error('Error fetching pool or DEXScreener data:', error);
            }
        };
        fetchPoolAndPairData();
    }, [currentTokenAddress]);

    useEffect(() => {
        if (pairData && amount) {
            const price = parseFloat(pairData.priceUsd);
            const amountNum = parseFloat(amount);
            if (!isNaN(price) && !isNaN(amountNum)) {
                setUsdValue((price * amountNum).toFixed(2));
            }
        } else {
            setUsdValue(null);
        }
    }, [amount, pairData]);

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    const handleSwap = useCallback(async () => {
        if (!isWalletConnected()) {
            alert('Please connect your wallet first.');
            return;
        }

        if (!amount || !currentTokenAddress) {
            alert('Please enter an amount and ensure the token address is loaded.');
            return;
        }

        setIsLoading(true);
        setTransactionStatus(null);

        try {
            const poolAddress = 'EQC9miYYHYfbL5xpFqhZvwJd2HbLfd1L8nkIumv2bod3cIVY'; // Replace with actual pool address
            const result = await performSwap(wallet, poolAddress, amount, mode, currentTokenAddress, sendTransaction);

            console.log('Swap initiated:', result);
            setTransactionStatus('Pending');

            const checkStatus = async () => {
                try {
                    const status = await checkTransactionStatus(result.transactionId);
                    if (status.isComplete) {
                        setTransactionStatus(status.success ? 'Success' : 'Failed');
                        setIsLoading(false);
                    } else {
                        setTimeout(checkStatus, 5000);
                    }
                } catch (error) {
                    console.error('Error checking transaction status:', error);
                    setTransactionStatus('Error');
                    setIsLoading(false);
                }
            };

            checkStatus();
        } catch (error) {
            console.error('Error performing swap:', error);
            setTransactionStatus('Error');
            setIsLoading(false);
            alert(`Error performing swap: ${error.message}`);
        }
    }, [wallet, isWalletConnected, sendTransaction, amount, mode, currentTokenAddress]);

    const toggleMode = () => {
        setMode(prevMode => prevMode === 'buy' ? 'sell' : 'buy');
    };

    return (
        <div className="swap-component mt-4">
            <div className="flex flex-col space-y-4">
                {pairData && (
                    <div className="text-sm text-gray-600 dark:text-gray-300">
                        <p>Price: ${parseFloat(pairData.priceUsd).toFixed(4)}</p>
                        <p>24h Change: {pairData.priceChange?.h24 ? `${pairData.priceChange.h24.toFixed(2)}%` : 'N/A'}</p>
                        <p>Liquidity: ${parseInt(pairData.liquidity?.usd).toLocaleString()}</p>
                        <p>Volume 24h: ${parseInt(pairData.volume?.h24).toLocaleString()}</p>
                        <p>FDV: ${parseInt(pairData.fdv).toLocaleString()}</p>
                    </div>
                )}
                <div className="flex justify-between items-center bg-gray-100 dark:bg-gray-700 rounded-lg p-2">
                    <button
                        className={`flex-1 py-2 px-4 rounded-lg transition-colors duration-200 ${mode === 'buy'
                                ? 'bg-blue-600 text-white'
                                : 'bg-transparent text-gray-600 dark:text-gray-300'
                            }`}
                        onClick={() => setMode('buy')}
                    >
                        Buy
                    </button>
                    <button
                        className={`flex-1 py-2 px-4 rounded-lg transition-colors duration-200 ${mode === 'sell'
                                ? 'bg-red-500 text-white'
                                : 'bg-transparent text-gray-600 dark:text-gray-300'
                            }`}
                        onClick={() => setMode('sell')}
                    >
                        Sell
                    </button>
                </div>
                <div className="flex items-center space-x-2">
                    <input
                        type="number"
                        value={amount}
                        onChange={handleAmountChange}
                        placeholder={`Amount to ${mode}`}
                        className="flex-1 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 dark:bg-gray-700 dark:text-white"
                    />
                    <button
                        onClick={toggleMode}
                        className="p-2 bg-gray-200 dark:bg-gray-600 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-500 transition-colors duration-200"
                    >
                        <FaExchangeAlt className="text-gray-600 dark:text-gray-300" />
                    </button>
                </div>
                {usdValue && (
                    <p className="text-sm text-gray-600 dark:text-gray-300">
                        ≈ ${usdValue} USD
                    </p>
                )}
                <button
                    onClick={handleSwap}
                    className={`w-full py-2 px-4 rounded-lg text-white font-semibold transition-colors duration-200 ${mode === 'buy' ? 'bg-blue-600 hover:bg-blue-700' : 'bg-red-500 hover:bg-red-600'
                        }`}
                    disabled={!isWalletConnected() || isLoading}
                >
                    {isLoading ? 'Processing...' : `${mode === 'buy' ? 'Buy' : 'Sell'} ${tokenSymbol}`}
                </button>
                {transactionStatus && (
                    <p className="text-center mt-2">
                        Transaction Status: {transactionStatus}
                    </p>
                )}
            </div>
        </div>
    );
};

export default SwapComponent;
