import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import VoteButton from '../UIX/VoteButton';
import { useWallet } from '../../contexts/WalletContext';
import { useTheme } from '../../contexts/ThemeContext';
import { FaComment } from 'react-icons/fa'; // Import the comment icon
import { fetchTokenData } from '../../components/fetch/FetchTokenData';
import { API_KEY } from '../../constants';
import { truncateUserId } from '../userUtils';
import ClickablePost from './ClickablePost';

const Post = ({ post, showTokenLink = false, onDelete, onVote }) => {
    console.log('Rendering post with ID:', post.id);  // Add this line for debugging
    const { userId } = useWallet();
    const { isDarkMode } = useTheme();
    const [tokenInfo, setTokenInfo] = useState(null);

    useEffect(() => {
        const getTokenData = async () => {
            if (showTokenLink && post.token_address) {
                const tokenData = await fetchTokenData(post.token_address);
                if (tokenData) {
                    setTokenInfo(tokenData);
                }
            }
        };

        getTokenData();
    }, [post.token_address, showTokenLink]);

    const handleDelete = async (e) => {
        e.preventDefault();
        if (window.confirm('Are you sure you want to delete this post? This action is undoable!')) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/posts/${post.id}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-API-Key': API_KEY
                    },
                    body: JSON.stringify({ userId }),
                });

                if (response.ok) {
                    onDelete(post.id);
                } else {
                    throw new Error('Failed to delete post');
                }
            } catch (error) {
                console.error('Error deleting post:', error);
                alert('Failed to delete post. Please try again.');
            }
        }
    };

    return (
        <ClickablePost post={post}>
            <div
                className={`post p-4 rounded-lg ${isDarkMode
                        ? 'bg-gray-900 hover:bg-gray-800'
                        : 'bg-white hover:bg-gray-100'
                    } text-${isDarkMode ? 'white' : 'black'} shadow transition-colors duration-200`}
            >
                <div className="flex justify-between items-start mb-4">
                    {showTokenLink && post.token_address && tokenInfo && (
                        <Link
                            to={`/tokenpage/${post.token_address}`}
                            className="flex items-center text-blue-600 hover:underline"
                            onClick={(e) => e.stopPropagation()}
                        >
                            {tokenInfo.metadata?.image && (
                                <img
                                    src={tokenInfo.metadata.image}
                                    alt={`${tokenInfo.name} logo`}
                                    className="w-8 h-8 mr-2 rounded-full"
                                />
                            )}
                            <span>{tokenInfo.name}</span>
                        </Link>
                    )}
                    <Link
                        to={`/user/${post.user_id}`}
                        className="text-blue-500 hover:underline"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {truncateUserId(post.user_id)}
                    </Link>
                </div>
                <h2 className="text-xl font-bold mb-2">{post.title}</h2>
                <p className="mb-2">{post.content}</p>
                {post.image && (
                    <img src={post.image} alt="Post" className="w-full h-auto mb-2 rounded" />
                )}
                <div className="mt-2 flex justify-between items-center">
                    <div className="flex items-center space-x-2">
                        <VoteButton
                            initialUpvotes={post.upvotes}
                            initialDownvotes={post.downvotes}
                            onVote={(voteData) => onVote(post.id, voteData)}
                            itemType="post"
                            itemId={post.id}
                        />
                        <div className="flex items-center text-gray-500">
                            <FaComment className="mr-1" />
                            <span>{post.comments ? post.comments.length : 0}</span>
                        </div>
                    </div>
                    {userId === post.user_id && (
                        <button
                            onClick={handleDelete}
                            className="text-red-500 hover:text-red-700"
                        >
                            Delete
                        </button>
                    )}
                </div>
            </div>
        </ClickablePost>
    );
};

export default Post;
