import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { useTonConnectUI } from '@tonconnect/ui-react';

const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
    const [tonConnectUI] = useTonConnectUI();
    const [wallet, setWallet] = useState(null);
    const [userId, setUserId] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const unsubscribe = tonConnectUI.onStatusChange((wallet) => {
            console.log("Wallet status changed:", wallet);
            setWallet(wallet);
        });
        return () => unsubscribe();
    }, [tonConnectUI]);

    useEffect(() => {
        if (wallet?.account?.address) {
            const formattedAddress = wallet.account.address.toString();
            setUserId(formattedAddress);
            console.log('Wallet connected, userId set to:', formattedAddress);
        } else {
            setUserId(null);
            console.log('Wallet disconnected, userId set to null');
        }
    }, [wallet]);

    const connect = useCallback(async () => {
        console.log("Attempting to connect wallet");
        try {
            const result = await tonConnectUI.connectWallet();
            console.log("Connection result:", result);
        } catch (error) {
            console.error('Error connecting wallet:', error);
            setError(error.message || 'Failed to connect wallet');
        }
    }, [tonConnectUI]);

    const disconnect = useCallback(async () => {
        console.log("Attempting to disconnect wallet");
        try {
            await tonConnectUI.disconnect();
            setWallet(null);
            setUserId(null);
        } catch (error) {
            console.error('Error disconnecting wallet:', error);
            setError(error.message || 'Failed to disconnect wallet');
        }
    }, [tonConnectUI]);

    const updateUserId = useCallback((newUserId) => {
        setUserId(newUserId);
        if (wallet?.account?.address) {
            const storedUserIds = JSON.parse(localStorage.getItem('userIds')) || {};
            storedUserIds[wallet.account.address] = newUserId;
            localStorage.setItem('userIds', JSON.stringify(storedUserIds));
        }
    }, [wallet]);

    const sendTransaction = useCallback(async (transaction) => {
        if (!tonConnectUI) {
            throw new Error('TonConnect UI is not initialized');
        }
        if (!wallet) {
            throw new Error('Wallet is not connected');
        }
        try {
            console.log('Sending transaction:', transaction);
            console.log('TonConnectUI state:', tonConnectUI);
            const result = await tonConnectUI.sendTransaction({
                validUntil: Math.floor(Date.now() / 1000) + 360, // 5 minutes from now
                messages: [
                    {
                        address: transaction.to.toString(),
                        amount: transaction.value.toString(),
                        payload: transaction.payload.toBoc().toString('base64'),
                    },
                ],
            });
            console.log('Transaction sent:', result);
            return result;
        } catch (error) {
            console.error('Error sending transaction:', error);
            if (error instanceof Error) {
                console.error('Error name:', error.name);
                console.error('Error message:', error.message);
                console.error('Error stack:', error.stack);
            }
            throw error;
        }
    }, [tonConnectUI, wallet]);

    const isWalletConnected = useCallback(() => {
        return !!wallet && !!wallet.account;
    }, [wallet]);

    return (
        <WalletContext.Provider value={{
            wallet,
            userId,
            connect,
            disconnect,
            updateUserId,
            sendTransaction,
            isWalletConnected,
            error
        }}>
            {children}
        </WalletContext.Provider>
    );
};

export const useWallet = () => {
    const context = useContext(WalletContext);
    if (context === undefined) {
        throw new Error('useWallet must be used within a WalletProvider');
    }
    return context;
};
