import React from 'react';
import { motion } from 'framer-motion';
import { FaRocket, FaPaintBrush, FaUsers, FaChartLine } from 'react-icons/fa';
import { useTheme } from '../../contexts/ThemeContext';

const Milestone = ({ icon, title, items, delay }) => {
    const { isDarkMode } = useTheme();
    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay }}
            className={`flex flex-col items-center p-4 rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'} shadow-lg`}
        >
            {React.createElement(icon, { className: "text-blue-500 text-4xl mb-2" })}
            <h3 className="text-lg font-bold mb-2">{title}</h3>
            <ul className="text-sm">
                {items.map((item, index) => (
                    <li key={index} className="mb-1 flex items-start">
                        <span className="mr-2 text-blue-500">•</span>
                        {item}
                    </li>
                ))}
            </ul>
        </motion.div>
    );
};

const RoadmapPage = () => {
    const { isDarkMode } = useTheme();
    const milestones = [
        {
            icon: FaRocket,
            title: "Launch MVP",
            items: [
                "Basic features",
                "TON jetton search",
                "Posting & commenting",
                "Wallet connection"
            ]
        },
        {
            icon: FaPaintBrush,
            title: "UI/UX Updates",
            items: [
                "Like counts",
                "Sorting algorithms",
                "Discover page",
                "Refine based on feedback"
            ]
        },
        {
            icon: FaUsers,
            title: "Expansion",
            items: [
                "Solana & Ethereum ecosystems",
                "Custom communities",
                "Enhanced user profiles"
            ]
        },
        {
            icon: FaChartLine,
            title: "Growth",
            items: [
                "Points & referrals",
                "1000 monthly active users",
                "Advanced analytics"
            ]
        }
    ];

    return (
        <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'} py-12`}>
            <div className="container mx-auto px-4">
                <motion.h1
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="text-4xl font-bold mb-8 text-center"
                >
                    Gether Roadmap
                </motion.h1>
                <motion.p
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    className="text-xl mb-12 text-center max-w-2xl mx-auto"
                >
                    The Community Layer of Blockchain
                </motion.p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    {milestones.map((milestone, index) => (
                        <Milestone key={index} {...milestone} delay={0.2 + index * 0.1} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RoadmapPage;
