// FetchTokenData.js

import { API_BASE_URL } from '../../config';
import { API_KEY } from '../../constants';
import axios from 'axios';

export const initApi = async () => {
    const apiUrl = 'https://tonapi.io/v2/';
    const apiKey = process.env.REACT_APP_TONAPI_KEY;
    return { apiUrl, apiKey };
};

export const fetchTokenData = async (tokenAddress) => {
    try {
        const response = await fetch(`${API_BASE_URL}/tokens/${tokenAddress}`, {
            headers: {
                'X-API-Key': API_KEY
            }
        });
        if (!response.ok) {
            if (response.status === 404) {
                console.log('Token not found in database, fetching from external API...');
                const externalData = await fetchExternalTokenData(tokenAddress);
                if (externalData) {
                    await saveTokenInfoToDatabase(tokenAddress, externalData);  // Use original tokenAddress here
                    return { ...externalData, address: tokenAddress };
                }
                console.log('Token not found in external API');
                return null;
            }
            const errorData = await response.json();
            throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message || 'Unknown error'}`);
        }
        const data = await response.json();
        console.log('Token data:', data);
        return data;
    } catch (error) {
        console.error(`Error fetching token info for ${tokenAddress}:`, error);
        return null;
    }
};

const fetchExternalTokenData = async (tokenAddress) => {
    try {
        const { apiUrl, apiKey } = await initApi();
        const response = await fetch(`${apiUrl}jettons/${tokenAddress}`, {
            headers: {
                'Authorization': `Bearer ${apiKey}`
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        
        // Transform the external API data to match our expected format
        const transformedData = {
            address: data.metadata.address,
            name: data.metadata.name,
            symbol: data.metadata.symbol,
            description: data.metadata.description,
            metadata: {
                ...data.metadata,
                image: data.metadata.image,
                social: data.metadata.social,
                websites: data.metadata.websites
            }
        };
        
        return transformedData;
    } catch (error) {
        console.error(`Error fetching external token info for ${tokenAddress}:`, error);
        return null;
    }
};

const saveTokenInfoToDatabase = async (address, tokenInfo) => {
    try {
        console.log('Attempting to save token info:', tokenInfo);
        console.log('Saving with original address:', address);
        const response = await fetch(`${API_BASE_URL}/tokens`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-Key': API_KEY
            },
            body: JSON.stringify({
                address: address,  // Use the original address here
                name: tokenInfo.name,
                symbol: tokenInfo.symbol,
                description: tokenInfo.description,
                metadata: tokenInfo.metadata
            }),
        });
        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error response:', errorData);
            throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.error || 'Unknown error'}, details: ${errorData.details || 'No details provided'}`);
        }
        console.log('Token info saved to database');
    } catch (error) {
        console.error('Error saving token info to database:', error);
        console.error('Error details:', error.message);
    }
};

export const fetchPosts = async (tokenAddress, sortOption = 'newest') => {
    try {
        console.log(`Fetching posts for token address: ${tokenAddress}`);
        let url = `${API_BASE_URL}/posts/${tokenAddress}`;

        if (sortOption === 'most_liked') {
            url += '?sort=likes';
        }

        const response = await axios.get(url, {
            headers: {
                'X-API-Key': API_KEY
            }
        });
        console.log(`Received ${response.data.length} posts from server`);
        return response.data;
    } catch (error) {
        console.error('Error loading posts:', error);
        return [];
    }
};
