import { getUserIdPrefix, truncateUserId } from '../userUtils';
import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { Link } from 'react-router-dom';

export const handleNewComment = (setPost, setReplyingTo) => (newComment) => {
    setPost(prevPost => {
        const updateComments = (comments) => {
            return comments.map(comment => {
                if (comment.id === newComment.parent_comment_id) {
                    return {
                        ...comment,
                        replies: [...(comment.replies || []), newComment]
                    };
                } else if (comment.replies && comment.replies.length > 0) {
                    return {
                        ...comment,
                        replies: updateComments(comment.replies)
                    };
                }
                return comment;
            });
        };

        if (newComment.parent_comment_id) {
            const updatedComments = updateComments(prevPost.comments || []);
            setReplyingTo(null);
            return {
                ...prevPost,
                comments: updatedComments
            };
        } else {
            return {
                ...prevPost,
                comments: [...(prevPost.comments || []), newComment]
            };
        }
    });
};

const CommentThread = ({ comment, depth = 0, handleReply, replyingTo, CommentForm, postId, onCommentSubmit, userId, isLastChild, onDelete }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const { isDarkMode } = useTheme();
    const replyFormRef = useRef(null);
    const linkColors = [
        'bg-blue-500',
        'bg-green-500',
        'bg-yellow-500',
        'bg-red-500',
        'bg-purple-500'
    ];

    const currentColor = linkColors[depth % linkColors.length];

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const handleDeleteWithConfirmation = () => {
        const isConfirmed = window.confirm("Are you sure you want to delete this comment? This action is undoable!");
        if (isConfirmed) {
            onDelete(comment.id);
        }
    };

    useEffect(() => {
        if (replyingTo === comment.id && replyFormRef.current) {
            replyFormRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [replyingTo, comment.id]);

    return (
        <div className={`relative ${depth > 0 ? 'ml-6' : ''} pt-2`}>
            <div
                className={`absolute left-0 top-0 bottom-0 w-1 ${currentColor} cursor-pointer hover:w-2 transition-all`}
                style={{ transform: 'translateX(-1.5rem)' }}
                onClick={toggleCollapse}
                title={isCollapsed ? "Expand comment" : "Collapse comment"}
            />
            <div
                className={`absolute left-0 top-8 w-6 h-1 ${currentColor}`}
                style={{ transform: 'translateX(-1.5rem)' }}
            />
            <div className={`relative p-3 rounded-lg break-words ${isDarkMode ? 'bg-gray-1100' : 'bg-white'}`}>
                <div className="flex flex-col space-y-2">
                    <div className="flex justify-between items-center">
                        <Link to={`/user/${comment.user_id}`} className="text-sm text-blue-500 hover:underline">
                            {truncateUserId(comment.user_id)}
                        </Link>
                        <p className="text-gray-500 text-xs">{new Date(comment.created_at).toLocaleString()}</p>
                    </div>
                    {!isCollapsed && (
                        <>
                            <p className={`text-base whitespace-pre-wrap break-words overflow-wrap-anywhere ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>{comment.content}</p>
                            <div className="flex space-x-2">
                                <button
                                    onClick={() => handleReply(comment.id)}
                                    className="text-blue-500 text-sm hover:underline self-start"
                                >
                                    Reply
                                </button>
                                {comment.user_id === userId && (
                                    <button 
                                        onClick={() => onDelete(comment.id)}
                                        className="text-red-500 text-sm hover:underline self-start"
                                    >
                                        Delete
                                    </button>
                                )}
                            </div>
                        </>
                    )}
                </div>
                {!isCollapsed && replyingTo === comment.id && (
                    <div ref={replyFormRef} className="mt-3">
                        <CommentForm
                            postId={postId}
                            parentCommentId={comment.id}
                            onCommentSubmit={onCommentSubmit}
                        />
                    </div>
                )}
            </div>
            {!isCollapsed && comment.replies && comment.replies.length > 0 && (
                <div className="relative">
                    {comment.replies.map((reply, index) => (
                        <CommentThread
                            key={`${reply._id}-${depth + 1}-${index}`}
                            comment={reply}
                            depth={depth + 1}
                            handleReply={handleReply}
                            replyingTo={replyingTo}
                            CommentForm={CommentForm}
                            postId={postId}
                            onCommentSubmit={onCommentSubmit}
                            userId={userId}
                            isLastChild={index === comment.replies.length - 1}
                            onDelete={onDelete}
                        />
                    ))}
                </div>
            )}
            {!isLastChild && (
                <div
                    className={`absolute left-0 top-0 bottom-0 w-px ${currentColor}`}
                    style={{ transform: 'translateX(-1.5rem)' }}
                />
            )}
        </div>
    );
};

export const renderComments = (comments, depth = 0, handleReply, replyingTo, CommentForm, postId, onCommentSubmit, userId, onDelete) => {
    return comments.map((comment, index) => (
        <CommentThread
            key={`${comment.id}-${depth}-${index}`}
            comment={comment}
            depth={depth}
            handleReply={handleReply}
            replyingTo={replyingTo}
            CommentForm={CommentForm}
            postId={postId}
            onCommentSubmit={onCommentSubmit}
            userId={userId}
            isLastChild={index === comments.length - 1}
            onDelete={onDelete}
        />
    ));
};

export default CommentThread;
