import React, { useState, useEffect } from 'react';
import { useWallet } from '../../contexts/WalletContext';
import { useTheme } from '../../contexts/ThemeContext';
import { fetchUserTokens } from '../services/services_api/tonService';
import { Link } from 'react-router-dom';

const TradePage = () => {
    const { wallet } = useWallet();
    const { isDarkMode } = useTheme();
    const [tokens, setTokens] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTokens = async () => {
            if (wallet && wallet.account) {
                try {
                    setLoading(true);
                    setError(null);
                    const fetchedTokens = await fetchUserTokens(wallet.account.address);
                    setTokens(fetchedTokens);
                } catch (err) {
                    console.error('Error fetching tokens:', err);
                    setError('Failed to fetch tokens. Please try again.');
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchTokens();
    }, [wallet]);

    if (loading) {
        return <div className="text-center">Loading tokens...</div>;
    }

    if (error) {
        return <div className="text-center text-red-500">{error}</div>;
    }

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Trading Terminal</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <Link to="/tokenpage/ton" className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-4 rounded-lg shadow`}>
                    <h2 className="text-xl font-semibold">TON</h2>
                    <p>Native token of the TON blockchain</p>
                    <p>Balance: {tokens.find(t => t.symbol === 'TON')?.balance || '0'} TON</p>
                </Link>
                {tokens.filter(token => token.symbol !== 'TON').map(token => (
                    <Link key={token.address} to={`/tokenpage/${token.address}`} className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-4 rounded-lg shadow`}>
                        <h2 className="text-xl font-semibold">{token.symbol}</h2>
                        <p>{token.name}</p>
                        <p>Balance: {token.balance} {token.symbol}</p>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default TradePage;
