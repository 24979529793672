import React, { useState } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { useWallet } from '../../contexts/WalletContext';
import { API_KEY } from '../../constants';

const PostForm = ({ currentTokenAddress, onPostCreated, compact = false }) => {
    const [postTitle, setPostTitle] = useState('');
    const [postContent, setPostContent] = useState('');
    const [file, setFile] = useState(null);
    const { isDarkMode } = useTheme();
    const { isWalletConnected, userId } = useWallet();

    const handleImageChange = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
    };

    const createPost = async () => {
        if (!isWalletConnected()) {
            console.log('Wallet not connected');
            return;
        }

        if (postTitle && postContent && currentTokenAddress) {
            try {
                const formData = new FormData();
                formData.append('user_id', userId);
                formData.append('token_address', currentTokenAddress);
                formData.append('title', postTitle);
                formData.append('content', postContent);

                if (file) {
                    formData.append('image', file);
                }

                console.log('Sending post data:', {
                    user_id: userId,
                    token_address: currentTokenAddress,
                    title: postTitle,
                    content: postContent,
                    image: file ? file.name : 'No image'
                });

                const response = await fetch(`${process.env.REACT_APP_API_URL}/posts`, {
                    method: 'POST',
                    headers: {
                        'X-API-Key': API_KEY
                    },
                    body: formData,
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(`HTTP error status: ${response.status}, message: ${errorData.error || 'Unknown error'}, details: ${errorData.details || 'No details provided'}`);
                }

                const post = await response.json();
                console.log('Post created:', post);

                setPostTitle('');
                setPostContent('');
                setFile(null);

                if (onPostCreated) {
                    onPostCreated(post);
                }
            } catch (error) {
                console.error('Error creating post:', error);
                alert(`Failed to create post: ${error.message}`);
            }
        } else {
            console.log('Missing title, content, or token address');
            console.log('Title:', postTitle);
            console.log('Content:', postContent);
            console.log('Token Address:', currentTokenAddress);
            alert('Please fill out all fields before posting.');
        }
    };

    return (
        <div className={`post-form ${compact ? 'p-2' : 'p-4'} rounded-lg ${isDarkMode ? 'bg-gray-900' : 'bg-white'} shadow-md`}>
            <h2 className={`${compact ? 'text-lg' : 'text-xl'} font-bold mb-2`}>Create a Post</h2>
            {!isWalletConnected() && (
                <div className="mb-4 p-2 bg-yellow-100 border border-yellow-400 text-yellow-700 rounded">
                    Please connect your wallet to make a post.
                </div>
            )}
            <input
                type="text"
                placeholder={isWalletConnected() ? "Title" : "Connect wallet to post"}
                value={postTitle}
                onChange={(e) => setPostTitle(e.target.value)}
                className={`w-full ${compact ? 'p-1 mb-1' : 'p-2 mb-2'} rounded-md border ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-100'}`}
                disabled={!isWalletConnected()}
            />
            <textarea
                placeholder={isWalletConnected() ? "Content" : "Connect wallet to post"}
                value={postContent}
                onChange={(e) => setPostContent(e.target.value)}
                className={`w-full ${compact ? 'p-1 mb-1' : 'p-2 mb-2'} rounded-md border ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-100'}`}
                rows={compact ? "2" : "4"}
                disabled={!isWalletConnected()}
            />
            <div className="flex items-center justify-between">
                <label className={`cursor-pointer ${compact ? 'text-sm' : ''} ${!isWalletConnected() ? 'opacity-50 cursor-not-allowed' : ''}`}>
                    <input
                        type="file"
                        onChange={handleImageChange}
                        className="hidden"
                        disabled={!isWalletConnected()}
                    />
                    {file ? 'One image chosen' : 'Upload Image'}
                </label>
                <button
                    onClick={createPost}
                    className={`py-1.5 px-3 rounded-md text-base font-semibold cursor-pointer ${isWalletConnected()
                        ? (isDarkMode ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-blue-600 text-white hover:bg-blue-700')
                        : 'bg-gray-400 text-gray-700 cursor-not-allowed'
                        } ${compact ? 'text-sm' : ''}`}
                    disabled={!isWalletConnected()}
                >
                    Post
                </button>
            </div>
        </div>
    );
};

export default PostForm;
