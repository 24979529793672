import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import TokenDetails from '../TokenDetails';
import Post from '../../utils/post/Post';
import PostForm from '../../utils/post/PostForm';
import { useTheme } from '../../contexts/ThemeContext';
import { FaCopy } from 'react-icons/fa';
import { fetchPosts, fetchTokenData } from '../fetch/FetchTokenData';

const TokenPage = () => {
    const { address } = useParams();
    const [tokenInfo, setTokenInfo] = useState(null);
    const [posts, setPosts] = useState([]);
    const [error, setError] = useState(null);
    const { isDarkMode } = useTheme();
    const [sortOption, setSortOption] = useState('newest');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const tokenData = await fetchTokenData(address || 'ton');

                if (!tokenData) {
                    setError('Token not found. It might not exist in our database or external API.');
                    setTokenInfo(null);
                    setPosts([]);
                    return;
                }

                setTokenInfo(tokenData);

                // Fetch posts after successfully fetching token info
                const postsData = await fetchPosts(address || 'ton', sortOption);
                setPosts(postsData);
            } catch (err) {
                console.error('Error fetching data:', err);
                setError(`Failed to load data. Error: ${err.message}`);
            }
        };

        fetchData();
    }, [address, sortOption]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(address || 'ton');
        alert('Address copied to clipboard!');
    };

    const handlePostCreated = (newPost) => {
        setPosts(prevPosts => [newPost, ...prevPosts]);
    };

    const handleSortChange = (e) => {
        setSortOption(e.target.value);
    };

    const handlePostDelete = (deletedPostId) => {
        setPosts(prevPosts => prevPosts.filter(post => post._id !== deletedPostId));
    };

    const handleVote = (postId, voteData) => {
        setPosts(prevPosts =>
            prevPosts.map(post =>
                post._id === postId
                    ? { ...post, upvotes: voteData.upvotes, downvotes: voteData.downvotes }
                    : post
            )
        );
    };

    if (error) return (
        <div className="container mx-auto p-4">
            <div className="text-red-500">{error}</div>
            <div className="mt-4">
                <Link to="/" className="text-blue-500 hover:underline">
                    Return to Home
                </Link>
            </div>
        </div>
    );
    if (!tokenInfo) {
        return (
            <div className="container mx-auto p-4">
                <h1 className="text-2xl font-bold mb-4">Token Not Found</h1>
                <p>The token with address {address} could not be found.</p>
                <div className="mt-4">
                    <Link to="/" className="text-blue-500 hover:underline">
                        Return to Home
                    </Link>
                </div>
            </div>
        );
    }

    return (
        <div className={`container mx-auto p-4 ${isDarkMode ? 'dark' : ''}`}>
            <div className="mb-8 text-center">
                {tokenInfo.metadata?.image && (
                    <img
                        src={tokenInfo.metadata.image}
                        alt={`${tokenInfo.name} logo`}
                        className="w-24 h-24 mx-auto mb-4 rounded-full"
                    />
                )}
                <h1 className="text-3xl font-bold mb-2">{tokenInfo.name} ({tokenInfo.symbol})</h1>
                <div
                    className="text-sm text-gray-500 hover:text-blue-500 cursor-pointer flex items-center justify-center"
                    onClick={copyToClipboard}
                >
                    <span className="mr-2">{address || 'ton'}</span>
                    <FaCopy />
                </div>
            </div>
            <div className="flex flex-col md:flex-row">
                {/* TokenDetails - Now it appears first on mobile */}
                <div className="w-full md:w-1/3 md:order-2 mb-8 md:mb-0 md:pl-4">
                    <TokenDetails tokenInfo={tokenInfo} currentTokenAddress={address || 'ton'} />
                </div>

                {/* Posts section */}
                <div className="w-full md:w-2/3 md:order-1 md:pr-4">
                    <PostForm
                        currentTokenAddress={address || 'ton'}
                        onPostCreated={handlePostCreated}
                        compact={true}
                    />
                    <div className="flex justify-between items-center mt-6 mb-4">
                        <h2 className="text-xl font-bold">Posts</h2>
                        <select
                            value={sortOption}
                            onChange={handleSortChange}
                            className={`p-2 rounded ${isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-black'}`}
                        >
                            <option value="newest">Newest</option>
                            <option value="most_liked">Most Liked</option>
                        </select>
                    </div>
                    {posts.length > 0 ? (
                        posts.map(post => (
                            <Link key={post._id} to={`/post/${post._id}`} className="block mb-4">
                                <Post
                                    post={post}
                                    showTokenLink={false}
                                    onDelete={handlePostDelete}
                                    onVote={handleVote}
                                />
                            </Link>
                        ))
                    ) : (
                        <p>No posts found for this token.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TokenPage;
