import { toNano, Address, beginCell } from '@ton/core';

export const performSwap = async (wallet, poolAddress, amount, mode, tokenAddress, sendTransaction) => {
    const swapAmount = toNano(amount);
    try {
        console.log('Building swap message...');
        const message = buildSwapMessage(swapAmount, tokenAddress, poolAddress, mode);
        console.log('Swap message built:', message);

        console.log('Sending transaction...');
        console.log('Wallet:', wallet);
        console.log('Pool Address:', poolAddress);
        console.log('Amount:', swapAmount);
        console.log('Mode:', mode);
        console.log('Token Address:', tokenAddress);

        if (!wallet || !wallet.account) {
            throw new Error('Wallet is not properly connected');
        }

        const transaction = {
            to: Address.parse(poolAddress),
            value: swapAmount,
            payload: message, // Change 'body' to 'payload'
        };

        const result = await sendTransaction(transaction);
        console.log('Transaction sent:', result);
        return result;
    } catch (error) {
        console.error('Error performing swap:', error);
        throw error;
    }
};

const buildSwapMessage = (amount, tokenAddress, poolAddress, mode) => {
    return beginCell()
        .storeUint(0x12345678, 32) // Replace with your actual method ID for swap
        .storeCoins(amount)
        .storeAddress(Address.parse(tokenAddress))
        .storeAddress(Address.parse(poolAddress))
        .storeUint(mode === 'buy' ? 1 : 2, 8)
        .endCell();
};
