import { TonClient } from '@ton/ton';

const client = new TonClient({
    endpoint: 'https://toncenter.com/api/v2/jsonRPC', // Replace with your preferred endpoint
});

export const checkTransactionStatus = async (transactionId) => {
    try {
        const transaction = await client.getTransaction(transactionId);
        return {
            isComplete: true,
            success: transaction.description.action.success && transaction.description.compute_ph.success,
        };
    } catch (error) {
        if (error.message.includes('transaction not found')) {
            return { isComplete: false };
        }
        throw error;
    }
};