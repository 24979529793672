import React, { useState } from 'react';
import SwapComponent from './SwapComponent';
import { useTheme } from '../contexts/ThemeContext';
import { FaExchangeAlt, FaChevronDown, FaChevronUp } from 'react-icons/fa';

const TokenDetails = ({ tokenInfo, currentTokenAddress }) => {
    const { isDarkMode } = useTheme();
    const [isSwapOpen, setIsSwapOpen] = useState(false);

    if (!tokenInfo) {
        return <div>Loading token details...</div>;
    }

    const tokenName = tokenInfo.name || tokenInfo.metadata?.name || 'Unknown Token';
    const tokenSymbol = tokenInfo.symbol || tokenInfo.metadata?.symbol || 'Unknown';

    return (
        <div className={`token-details p-4 rounded-lg ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-black'}`}>
            <h2 className="text-2xl font-bold mb-4">Token Details</h2>
            <p className="mb-2"><strong>Description:</strong> {tokenInfo.description || tokenInfo.metadata?.description || 'No description available'}</p>

            {tokenInfo.priceUsd && (
                <p className="mb-2"><strong>Price:</strong> ${parseFloat(tokenInfo.priceUsd).toFixed(4)}</p>
            )}
            {tokenInfo.priceChange?.h24 && (
                <p className="mb-2"><strong>24h Change:</strong> {tokenInfo.priceChange.h24.toFixed(2)}%</p>
            )}
            {tokenInfo.liquidity?.usd && (
                <p className="mb-2"><strong>Liquidity:</strong> ${parseInt(tokenInfo.liquidity.usd).toLocaleString()}</p>
            )}
            {tokenInfo.volume?.h24 && (
                <p className="mb-2"><strong>Volume 24h:</strong> ${parseInt(tokenInfo.volume.h24).toLocaleString()}</p>
            )}
            {tokenInfo.fdv && (
                <p className="mb-2"><strong>FDV:</strong> ${parseInt(tokenInfo.fdv).toLocaleString()}</p>
            )}
            {tokenInfo.totalSupply && (
                <p className="mb-2"><strong>Total Supply:</strong> {parseInt(tokenInfo.totalSupply).toLocaleString()} {tokenSymbol}</p>
            )}
            {tokenInfo.circulatingSupply && (
                <p className="mb-2"><strong>Circulating Supply:</strong> {parseInt(tokenInfo.circulatingSupply).toLocaleString()} {tokenSymbol}</p>
            )}
            {tokenInfo.marketCap && (
                <p className="mb-2"><strong>Market Cap:</strong> ${parseInt(tokenInfo.marketCap).toLocaleString()}</p>
            )}

            {tokenInfo.metadata?.websites && tokenInfo.metadata.websites.length > 0 && (
                <p className="mb-2">
                    <strong>Website:</strong>{' '}
                    <a href={tokenInfo.metadata.websites[0]} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                        {tokenInfo.metadata.websites[0]}
                    </a>
                </p>
            )}
            {tokenInfo.metadata?.socials && (
                <div className="mb-2">
                    <strong>Social Links:</strong>
                    <ul className="list-disc list-inside">
                        {tokenInfo.metadata.socials.map((social, index) => (
                            <li key={index}>
                                <a href={social.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                                    {social.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <button
                onClick={() => setIsSwapOpen(!isSwapOpen)}
                className={`w-full mt-4 py-2 px-4 rounded-lg flex items-center justify-center transition-colors duration-200 ${isDarkMode
                        ? 'bg-blue-600 hover:bg-blue-700 text-white'
                        : 'bg-blue-500 hover:bg-blue-600 text-white'
                    }`}
            >
                <FaExchangeAlt className="mr-2" />
                {isSwapOpen ? 'Hide Swap' : `Swap ${tokenSymbol}`}
                {isSwapOpen ? <FaChevronUp className="ml-2" /> : <FaChevronDown className="ml-2" />}
            </button>
            {isSwapOpen && (
                <div className="mt-4 transition-all duration-300 ease-in-out">
                    <SwapComponent currentTokenAddress={currentTokenAddress} tokenSymbol={tokenSymbol} />
                </div>
            )}
        </div>
    );
};

export default TokenDetails;
