import React, { useState, useRef, useEffect } from 'react';
import { useWallet } from '../contexts/WalletContext';
import { useNavigate } from 'react-router-dom';

const CustomTonConnectButton = () => {
    const { wallet, connect, disconnect } = useWallet();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();

    const handleConnect = async () => {
        try {
            await connect();
        } catch (error) {
            console.error('Wallet connection error:', error);
            alert('Failed to connect wallet. Please try again.');
        }
    };

    const handleDisconnect = async () => {
        try {
            await disconnect();
            setIsOpen(false);
        } catch (error) {
            console.error('Wallet disconnection error:', error);
            alert('Failed to disconnect wallet. Please try again.');
        }
    };

    const handleViewProfile = () => {
        if (wallet && wallet.account) {
            navigate(`/user/${wallet.account.address}`);
            setIsOpen(false);
        }
    };

    const handleTrade = () => {
        if (wallet && wallet.account) {
            navigate('/trade');
            setIsOpen(false);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative" ref={dropdownRef}>
            <button
                onClick={() => wallet ? setIsOpen(!isOpen) : handleConnect()}
                className="px-3 py-1 rounded-md bg-blue-600 text-white text-sm hover:bg-blue-700 transition-colors duration-300"
            >
                {wallet ? '•••' : 'Connect'}
            </button>
            {isOpen && wallet && (
                <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <button
                            onClick={handleViewProfile}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                            role="menuitem"
                        >
                            View Profile
                        </button>
                        <button
                            onClick={handleTrade}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                            role="menuitem"
                        >
                            Trade
                        </button>
                        <button
                            onClick={handleDisconnect}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                            role="menuitem"
                        >
                            Disconnect
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomTonConnectButton;
