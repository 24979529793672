import React, { useState } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { useWallet } from '../../contexts/WalletContext';
import { API_KEY } from '../../constants';

const CommentForm = ({ postId, parentCommentId, onCommentSubmit }) => {
    const [commentContent, setCommentContent] = useState('');
    const { isDarkMode } = useTheme();
    const { isWalletConnected, userId } = useWallet();

    const createComment = async (e) => {
        e.preventDefault();
        if (!isWalletConnected()) {
            console.log('Wallet not connected');
            return;
        }
        if (commentContent) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/comments`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-API-Key': API_KEY
                    },
                    body: JSON.stringify({
                        post_id: postId,
                        parent_comment_id: parentCommentId,
                        user_id: userId,
                        content: commentContent
                    }),
                });
                const newComment = await response.json();
                console.log('New comment created:', newComment);  // Add this line for debugging
                setCommentContent('');
                if (onCommentSubmit) onCommentSubmit(newComment);
            } catch (error) {
                console.error('Error creating comment:', error);
            }
        }
    };

    return (
        <form onSubmit={createComment} className="mt-4">
            {!isWalletConnected() && (
                <div className="mb-2 p-2 bg-yellow-100 border border-yellow-400 text-yellow-700 rounded text-sm">
                    Please connect your wallet to post a comment.
                </div>
            )}
            <textarea
                value={commentContent}
                onChange={(e) => setCommentContent(e.target.value)}
                placeholder={isWalletConnected() ? "Write a comment..." : "Connect wallet to comment"}
                className={`w-full p-2 rounded-md ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-gray-100'}`}
                rows="3"
                disabled={!isWalletConnected()}
            />
            <button
                type="submit"
                className={`mt-2 px-4 py-2 rounded-md ${isWalletConnected()
                        ? (isDarkMode ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-blue-500 text-white hover:bg-blue-600')
                        : 'bg-gray-400 text-gray-700 cursor-not-allowed'
                    }`}
                disabled={!isWalletConnected()}
            >
                Submit
            </button>
        </form>
    );
};

export default CommentForm;
