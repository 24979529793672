import React from 'react';
import { Link } from 'react-router-dom';
import { FaXTwitter, FaGithub } from 'react-icons/fa6';
import { useTheme } from '../../contexts/ThemeContext';

const Footer = () => {
  const { isDarkMode } = useTheme();

  const iconColor = isDarkMode ? "white" : "black";

  return (
    <div className={`
      fixed bottom-0 left-0 right-0 h-[60px] 
      ${isDarkMode ? 'bg-[rgba(15,15,15,0.5)]' : 'bg-[rgba(255,255,255,0.5)]'} 
      border-t ${isDarkMode ? 'border-[rgba(255,255,255,0.1)]' : 'border-[rgba(0,0,0,0.1)]'} 
      flex justify-center items-center z-30 pointer-events-auto
      backdrop-blur-md
    `}>
      <Link to="/roadmap" className="mx-4 transition-transform duration-200 hover:scale-110">
        Roadmap
      </Link>
      <a href="https://x.com/getherlol" target="_blank" rel="noopener noreferrer" className="mx-4 transition-transform duration-200 hover:scale-110">
        <FaXTwitter size={24} color={iconColor} />
      </a>
    </div>
  );
};

export default Footer;
