import axios from 'axios';

const DEX_SCREENER_API_BASE_URL = 'https://api.dexscreener.com/latest/dex';

export const fetchTokenPairData = async (tokenAddress) => {
    try {
        const response = await axios.get(`${DEX_SCREENER_API_BASE_URL}/tokens/${tokenAddress}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching DEXScreener data:', error);
        throw error;
    }
};

export const searchTokens = async (query) => {
    try {
        const response = await axios.get(`${DEX_SCREENER_API_BASE_URL}/search/?q=${query}`);
        return response.data;
    } catch (error) {
        console.error('Error searching tokens on DEXScreener:', error);
        throw error;
    }
};
