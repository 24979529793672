import { API_BASE_URL } from '../../config';
import { API_KEY } from '../../constants';

export const fetchUserProfile = async (walletAddress) => {
    try {
        console.log('Fetching user profile for wallet:', walletAddress);
        const response = await fetch(`${API_BASE_URL}/users/${walletAddress}`, {
            headers: {
                'Content-Type': 'application/json',
                'X-API-Key': API_KEY
            }
        });
        console.log('Fetch user profile response:', response);
        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error response:', errorData);
            throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.error || 'Unknown error'}`);
        }
        const data = await response.json();
        console.log('Fetched user profile raw data:', data);
        return data;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        return {
            walletAddress: walletAddress,
            displayName: 'Anonymous User',
            bio: '',
            profilePicture: '',
            email: '',
            socialLinks: {},
            totalLikesReceived: 0
        };
    }
};

const createUserProfile = async (walletAddress) => {
    if (!walletAddress) {
        throw new Error('Wallet address is required');
    }
    try {
        const response = await fetch(`${API_BASE_URL}/users`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-Key': API_KEY
            },
            body: JSON.stringify({ walletAddress, displayName: '', bio: '', profilePicture: '' }),
        });
        console.log('Create user profile response:', response);
        const data = await response.json();
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}, message: ${data.error || 'Unknown error'}`);
        }
        console.log('Created user profile:', data);
        return data;
    } catch (error) {
        console.error('Error creating user profile:', error);
        throw error;
    }
};

export const fetchUserPosts = async (walletAddress) => {
    try {
        console.log('Fetching posts for user (frontend):', walletAddress);
        const response = await fetch(`${API_BASE_URL}/posts/user/${walletAddress}`, {
            headers: {
                'Content-Type': 'application/json',
                'X-API-Key': API_KEY
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Fetched user posts response:', response);
        console.log('Fetched user posts data:', data);
        return data;
    } catch (error) {
        console.error('Error fetching user posts:', error);
        return [];
    }
};

export const updateUserProfile = async (walletAddress, formData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${walletAddress}`, {
            method: 'PUT',
            headers: {
                'X-API-Key': API_KEY
            },
            body: formData
        });

        if (!response.ok) {
            throw new Error('Failed to update profile');
        }

        const updatedProfile = await response.json();
        console.log('Updated profile received:', updatedProfile);
        return updatedProfile;
    } catch (error) {
        console.error('Error updating user profile:', error);
        throw error;
    }
};

export const fetchAllUsers = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/users`, {
            headers: {
                'Content-Type': 'application/json',
                'X-API-Key': API_KEY
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching all users:', error);
        return [];
    }
};
