import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { fetchPostDetails } from '../fetch/FetchPost';
import CommentForm from '../../utils/comment/CommentForm';
import VoteButton from '../../utils/UIX/VoteButton';
import { useWallet } from '../../contexts/WalletContext';
import { useTheme } from '../../contexts/ThemeContext';
import { truncateUserId } from '../../utils/userUtils';
import CommentThread from '../../utils/comment/commentUtils';
import { fetchTokenData } from '../fetch/FetchTokenData';
import { API_KEY } from '../../constants';
import { API_BASE_URL } from '../../config';

const PostDetails = () => {
    const { postId } = useParams();
    console.log('PostDetails rendered with postId:', postId);  // Add this line for debugging

    const [post, setPost] = useState(null);
    const [error, setError] = useState(null);
    const { userId } = useWallet();
    const { isDarkMode } = useTheme();
    const navigate = useNavigate();
    const [replyingTo, setReplyingTo] = useState(null);
    const [tokenInfo, setTokenInfo] = useState(null);

    const loadPostDetails = useCallback(async () => {
        if (!postId) {
            setError('No post ID provided');
            return;
        }
        try {
            console.log('Attempting to fetch post details for postId:', postId);
            const { post } = await fetchPostDetails(postId);
            console.log('Received post details:', post);
            if (post) {
                setPost(post);
                if (post.token_address) {
                    const tokenData = await fetchTokenData(post.token_address);
                    if (tokenData) {
                        setTokenInfo(tokenData);
                    }
                }
            } else {
                setError('Post not found');
            }
        } catch (error) {
            console.error('Error loading post details:', error);
            setError('Failed to load post details. Please try again later.');
        }
    }, [postId]);

    useEffect(() => {
        loadPostDetails();
    }, [loadPostDetails]);

    const handleCommentSubmit = (newComment) => {
        setPost(prevPost => {
            const updateComments = (comments) => {
                return comments.map(comment => {
                    if (comment.id === newComment.parent_comment_id) {
                        return {
                            ...comment,
                            replies: [...(comment.replies || []), newComment]
                        };
                    } else if (comment.replies && comment.replies.length > 0) {
                        return {
                            ...comment,
                            replies: updateComments(comment.replies)
                        };
                    }
                    return comment;
                });
            };

            if (newComment.parent_comment_id) {
                return {
                    ...prevPost,
                    comments: updateComments(prevPost.comments)
                };
            } else {
                return {
                    ...prevPost,
                    comments: [...prevPost.comments, newComment]
                };
            }
        });
        setReplyingTo(null);
    };

    const handleCommentDelete = async (commentId) => {
        if (window.confirm('Are you sure you want to delete this comment? This action is undoable!')) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/comments/${commentId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-API-Key': API_KEY
                    },
                    body: JSON.stringify({ userId }),
                });

                if (response.ok) {
                    setPost(prevPost => {
                        const removeComment = (comments) => {
                            return comments.filter(comment => {
                                if (comment.id === commentId) {
                                    return false;
                                }
                                if (comment.replies && comment.replies.length > 0) {
                                    comment.replies = removeComment(comment.replies);
                                }
                                return true;
                            });
                        };

                        const updatedComments = removeComment(prevPost.comments);
                        return {
                            ...prevPost,
                            comments: updatedComments
                        };
                    });
                    console.log(`Comment ${commentId} deleted successfully`);
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Failed to delete comment');
                }
            } catch (error) {
                console.error('Error deleting comment:', error);
                alert('Failed to delete comment. Please try again.');
            }
        }
    };

    const handleDeletePost = async () => {
        if (window.confirm("Are you sure you want to delete this post? This action is undoable!")) {
            try {
                const response = await fetch(`${API_BASE_URL}/posts/${postId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-API-Key': API_KEY
                    },
                    body: JSON.stringify({ userId }),
                });

                if (response.ok) {
                    navigate('/');
                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Failed to delete post');
                }
            } catch (error) {
                console.error('Error deleting post:', error);
                alert(`Failed to delete post: ${error.message}`);
            }
        }
    };

    if (error) {
        return <div className="text-red-500">{error}</div>;
    }

    if (!post) {
        return <div>Loading...</div>;
    }

    return (
        <div className={`container mx-auto p-4 ${isDarkMode ? 'text-white' : 'text-black'}`}>
            <div className="flex justify-between items-start mb-4">
                {post.token_address && tokenInfo && (
                    <Link 
                        to={`/tokenpage/${post.token_address}`} 
                        className="flex items-center text-blue-600 hover:text-blue-700 transition-colors"
                    >
                        {tokenInfo.metadata?.image && (
                            <img 
                                src={tokenInfo.metadata.image} 
                                alt={`${tokenInfo.name} logo`} 
                                className="w-8 h-8 mr-2 rounded-full"
                            />
                        )}
                        <span>{tokenInfo.name}</span>
                    </Link>
                )}
                <Link to={`/user/${post.user_id}`} className="text-blue-500 hover:underline">
                    {truncateUserId(post.user_id)}
                </Link>
            </div>
            <h1 className="text-2xl font-bold mb-4">{post.title}</h1>
            <p className="mb-4">{post.content}</p>
            {post.image && (
                <img src={post.image} alt="Post" className="w-full h-auto mb-4 rounded" />
            )}
            <VoteButton
                initialUpvotes={post.upvotes}
                initialDownvotes={post.downvotes}
                itemType="post"
                itemId={post.id}
            />
            {userId === post.user_id && (
                <button
                    onClick={handleDeletePost}
                    className="mt-2 px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                >
                    Delete Post
                </button>
            )}
            <CommentForm postId={post.id} onCommentSubmit={handleCommentSubmit} />
            <div className="mt-8">
                <h2 className="text-xl font-semibold mb-4">Comments</h2>
                {post.comments && post.comments.map((comment, index) => (
                    <CommentThread
                        key={`${comment.id}-${index}`}
                        comment={comment}
                        depth={0}
                        handleReply={setReplyingTo}
                        replyingTo={replyingTo}
                        CommentForm={CommentForm}
                        postId={post.id}
                        onCommentSubmit={handleCommentSubmit}
                        userId={userId}
                        isLastChild={index === post.comments.length - 1}
                        onDelete={handleCommentDelete}
                    />
                ))}
            </div>
        </div>
    );
};

export default PostDetails;
