import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';

const ClickablePost = ({ post, children }) => {
    const navigate = useNavigate();
    const { isDarkMode } = useTheme();

    const handlePostClick = (e) => {
        e.preventDefault();
        console.log('Clicking post:', post);  // Log the entire post object
        if (post && post.id) {
            console.log('Navigating to:', `/post/${post.id}`);
            navigate(`/post/${post.id}`);
        } else {
            console.error('Post ID is undefined:', post);
        }
    };

    return (
        <div 
            onClick={handlePostClick}
            className={`rounded-lg mb-4 ${
                isDarkMode 
                    ? 'bg-gray-900 hover:bg-gray-800' 
                    : 'bg-white hover:bg-gray-100'
            } transition-colors duration-200 cursor-pointer`}
        >
            {children}
        </div>
    );
};

export default ClickablePost;
