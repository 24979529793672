import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useWallet } from '../../contexts/WalletContext';
import { useTheme } from '../../contexts/ThemeContext';
import { fetchUserProfile, fetchUserPosts, updateUserProfile } from '../fetch/FetchUserData';
import Post from '../../utils/post/Post';
import { API_KEY } from '../../constants';

const UserProfile = () => {
    const { walletAddress: profileWalletAddress } = useParams();
    const { wallet, userId: loggedInWalletAddress } = useWallet();
    const { isDarkMode } = useTheme();
    const [userProfile, setUserProfile] = useState(null);
    const [userPosts, setUserPosts] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [profileData, setProfileData] = useState({
        displayName: '',
        username: '',
        bio: '',
        email: '',
        socialLinks: {
            twitter: '',
            telegram: '',
            website: ''
        }
    });
    const [profilePicture, setProfilePicture] = useState(null);
    const [error, setError] = useState(null);
    const [currentProfilePicture, setCurrentProfilePicture] = useState(null);

    useEffect(() => {
        const loadUserData = async () => {
            if (!profileWalletAddress) {
                setError('No wallet address provided');
                return;
            }
            try {
                console.log('Loading user data for:', profileWalletAddress);
                const profile = await fetchUserProfile(profileWalletAddress);
                console.log('Fetched user profile in component:', profile);
                setUserProfile(profile);
                setProfileData({
                    displayName: profile.displayname || '',
                    username: profile.username || '',
                    bio: profile.bio || '',
                    email: profile.email || '',
                    socialLinks: profile.sociallinks || {}
                });
                setCurrentProfilePicture(profile.profilepicture);
                console.log('Set profile data:', profileData);

                console.log('Fetching user posts for:', profileWalletAddress);
                const posts = await fetchUserPosts(profileWalletAddress);
                console.log('Fetched user posts:', posts);
                setUserPosts(posts);
            } catch (error) {
                console.error('Error loading user data:', error);
                setError(`Failed to load user profile: ${error.message}`);
            }
        };
        loadUserData();
    }, [profileWalletAddress]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfileData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSocialLinkChange = (e) => {
        const { name, value } = e.target;
        setProfileData(prevData => ({
            ...prevData,
            socialLinks: {
                ...prevData.socialLinks,
                [name]: value
            }
        }));
    };

    const handleFileChange = (e) => {
        setProfilePicture(e.target.files[0]);
    };

    const handleUpdateProfile = async () => {
        try {
            const formData = new FormData();
            Object.keys(profileData).forEach(key => {
                if (key === 'socialLinks') {
                    formData.append(key, JSON.stringify(profileData[key]));
                } else {
                    formData.append(key, profileData[key]);
                }
            });
            if (profilePicture) {
                formData.append('profilePicture', profilePicture);
            } else if (currentProfilePicture) {
                formData.append('profilePicture', currentProfilePicture);
            }

            const updatedProfile = await updateUserProfile(profileWalletAddress, formData);
            setUserProfile(updatedProfile);
            setProfileData({
                displayName: updatedProfile.displayname || '',
                username: updatedProfile.username || '',
                bio: updatedProfile.bio || '',
                email: updatedProfile.email || '',
                socialLinks: updatedProfile.sociallinks || {}
            });
            setCurrentProfilePicture(updatedProfile.profilepicture);
            setIsEditing(false);
            console.log('Profile updated successfully:', updatedProfile);
        } catch (error) {
            console.error('Error updating profile:', error);
            setError('Failed to update profile. Please try again.');
        }
    };

    const isOwnProfile = loggedInWalletAddress === profileWalletAddress;

    if (error) {
        return <div className="text-red-500">{error}</div>;
    }

    const inputClasses = `w-full p-2 border rounded ${isDarkMode
        ? 'bg-gray-800 text-white border-gray-600'
        : 'bg-white text-black border-gray-300'
        }`;

    const handlePostDelete = async (postId) => {
        if (window.confirm('Are you sure you want to delete this post? This action is undoable!')) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/posts/${postId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-API-Key': API_KEY
                    },
                    body: JSON.stringify({ userId: loggedInWalletAddress }),
                });

                if (response.ok) {
                    setUserPosts(prevPosts => prevPosts.filter(post => post.id !== postId));
                } else {
                    throw new Error('Failed to delete post');
                }
            } catch (error) {
                console.error('Error deleting post:', error);
                alert('Failed to delete post. Please try again.');
            }
        }
    };

    const handleVote = async (postId, voteData) => {
        setUserPosts(prevPosts =>
            prevPosts.map(post =>
                post.id === postId
                    ? { ...post, upvotes: voteData.upvotes, downvotes: voteData.downvotes }
                    : post
            )
        );
    };

    return (
        <div className={`container mx-auto px-4 ${isDarkMode ? 'text-white' : 'text-black'}`}>
            <div className="max-w-4xl mx-auto">
                <div className={`${isDarkMode ? 'bg-gray-900' : 'bg-white'} shadow-lg rounded-lg overflow-hidden`}>
                    <div className="p-6">
                        <div className="flex flex-col md:flex-row">
                            <div className="md:w-1/3 mb-4 md:mb-0">
                                <img
                                    src={currentProfilePicture || 'https://via.placeholder.com/150'}
                                    alt="Profile"
                                    className="w-32 h-32 rounded-full mx-auto object-cover"
                                />
                            </div>
                            <div className="md:w-2/3 md:pl-6">
                                <h1 className="text-2xl font-bold mb-2">
                                    {userProfile?.displayname || profileData.displayName || 'Anonymous User'}
                                </h1>
                                <p className="text-gray-600 dark:text-gray-400 mb-4">{userProfile?.bio || 'No bio available'}</p>
                                {isOwnProfile && !isEditing && (
                                    <button
                                        onClick={() => setIsEditing(true)}
                                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-300"
                                    >
                                        Edit Profile
                                    </button>
                                )}
                            </div>
                        </div>
                        {isEditing && (
                            <div className="mt-6">
                                <h2 className="text-xl font-semibold mb-4">Edit Profile</h2>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <input
                                        type="text"
                                        name="displayName"
                                        value={profileData.displayName}
                                        onChange={handleInputChange}
                                        placeholder="Display Name"
                                        className={inputClasses}
                                    />
                                    <input
                                        type="text"
                                        name="username"
                                        value={profileData.username}
                                        onChange={handleInputChange}
                                        placeholder="Username"
                                        className={inputClasses}
                                    />
                                    <input
                                        type="email"
                                        name="email"
                                        value={profileData.email}
                                        onChange={handleInputChange}
                                        placeholder="Email"
                                        className={inputClasses}
                                    />
                                    <input
                                        type="text"
                                        name="twitter"
                                        value={profileData.socialLinks.twitter}
                                        onChange={handleSocialLinkChange}
                                        placeholder="Twitter"
                                        className={inputClasses}
                                    />
                                    <input
                                        type="text"
                                        name="telegram"
                                        value={profileData.socialLinks.telegram}
                                        onChange={handleSocialLinkChange}
                                        placeholder="Telegram"
                                        className={inputClasses}
                                    />
                                    <input
                                        type="text"
                                        name="website"
                                        value={profileData.socialLinks.website}
                                        onChange={handleSocialLinkChange}
                                        placeholder="Website"
                                        className={inputClasses}
                                    />
                                    <textarea
                                        name="bio"
                                        value={profileData.bio}
                                        onChange={handleInputChange}
                                        placeholder="Bio"
                                        className={`${inputClasses} col-span-2`}
                                        rows="4"
                                    ></textarea>
                                    <div className="col-span-2">
                                        {currentProfilePicture && (
                                            <div className="mb-2">
                                                <p>Current Profile Picture:</p>
                                                <img
                                                    src={currentProfilePicture}
                                                    alt="Current Profile"
                                                    className="w-32 h-32 rounded-full object-cover"
                                                />
                                            </div>
                                        )}
                                        <input
                                            type="file"
                                            onChange={handleFileChange}
                                            className={`${inputClasses}`}
                                        />
                                    </div>
                                    <button
                                        onClick={handleUpdateProfile}
                                        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300 col-span-2"
                                    >
                                        Save Profile
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="mt-8">
                    <h2 className="text-2xl font-bold mb-4">User Posts</h2>
                    {userPosts.length > 0 ? (
                        userPosts.map(post => (
                            <Link key={post.id} to={`/post/${post.id}`} className="block mb-4">
                                <Post
                                    post={post}
                                    showTokenLink={true}
                                    onDelete={handlePostDelete}
                                    onVote={handleVote}
                                />
                            </Link>
                        ))
                    ) : (
                        <p>No posts found for this user.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserProfile;
