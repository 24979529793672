import React, { useState, useEffect } from 'react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { useWallet } from '../../contexts/WalletContext';
import { API_KEY } from '../../constants';

const VoteButton = ({ initialUpvotes, initialDownvotes, onVote, itemType, itemId }) => {
    const [upvotes, setUpvotes] = useState(initialUpvotes || 0);
    const [downvotes, setDownvotes] = useState(initialDownvotes || 0);
    const [userVote, setUserVote] = useState(null);
    const [isVoting, setIsVoting] = useState(false);
    const { isWalletConnected, userId } = useWallet();

    useEffect(() => {
        const fetchUserVote = async () => {
            if (!itemId) {
                console.error('No itemId provided to VoteButton');
                return;
            }
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/${itemType}s/${itemId}/user-vote`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-API-Key': API_KEY,
                        'user-id': userId  // Make sure you're sending the user ID in the headers
                    }
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setUserVote(data.voteType);
            } catch (error) {
                console.error('Failed to fetch user vote:', error);
            }
        };

        fetchUserVote();
    }, [isWalletConnected, userId, itemType, itemId]);

    const handleVote = async (e, voteType) => {
        e.preventDefault();  // Prevent default link behavior
        e.stopPropagation(); // Stop event from bubbling up

        if (!isWalletConnected()) {
            alert('Please connect your wallet to vote.');
            return;
        }

        if (isVoting) return;

        setIsVoting(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/${itemType}s/${itemId}/vote`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Key': API_KEY
                },
                body: JSON.stringify({ voteType, userId }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            
            // Update vote counts based on the previous vote and new vote
            if (userVote === 'upvote' && voteType === 'downvote') {
                setUpvotes(prev => prev - 1);
                setDownvotes(prev => prev + 1);
            } else if (userVote === 'downvote' && voteType === 'upvote') {
                setUpvotes(prev => prev + 1);
                setDownvotes(prev => prev - 1);
            } else if (userVote === null) {
                if (voteType === 'upvote') setUpvotes(prev => prev + 1);
                if (voteType === 'downvote') setDownvotes(prev => prev + 1);
            } else if (voteType === userVote) {
                if (voteType === 'upvote') setUpvotes(prev => prev - 1);
                if (voteType === 'downvote') setDownvotes(prev => prev - 1);
            }

            setUserVote(userVote === voteType ? null : voteType);
            if (onVote) onVote(data);
        } catch (error) {
            console.error('Error voting:', error);
            alert('Failed to vote. Please try again.');
        } finally {
            setIsVoting(false);
        }
    };

    const voteCount = upvotes - downvotes;

    return (
        <div className="flex items-center space-x-2" onClick={(e) => e.stopPropagation()}>
            <button
                onClick={(e) => handleVote(e, 'upvote')}
                className={`text-gray-500 hover:text-green-500 ${userVote === 'upvote' ? 'text-green-500' : ''}`}
                disabled={isVoting}
            >
                <FaArrowUp />
            </button>
            <span>{voteCount}</span>
            <button
                onClick={(e) => handleVote(e, 'downvote')}
                className={`text-gray-500 hover:text-red-500 ${userVote === 'downvote' ? 'text-red-500' : ''}`}
                disabled={isVoting}
            >
                <FaArrowDown />
            </button>
        </div>
    );
};

export default VoteButton;
