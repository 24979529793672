import React from 'react';
import { useLocation } from 'react-router-dom';
import CustomTonConnectButton from '../TonConnectButton';
import LogoHeader from './LogoHeader';
import SearchContainer from './SearchContainer';
import { useIsPhone } from '../../utils/useMediaQuery';
import { useTheme } from '../../contexts/ThemeContext';
import { FaSun, FaMoon } from 'react-icons/fa';

function Header({ userId, onWalletConnect }) {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const isPhone = useIsPhone();
  const { isDarkMode, toggleDarkMode } = useTheme();

  return (
    <header className={`
      App-header fixed top-0 left-0 right-0 z-50 
      flex items-center justify-between px-4 py-2 h-16 w-full 
      ${isDarkMode ? 'bg-[rgba(15,15,15,0.5)]' : 'bg-[rgba(255,255,255,0.5)]'} 
      ${isDarkMode ? 'text-white' : 'text-gray-900'}
      border-b ${isDarkMode ? 'border-[rgba(255,255,255,0.1)]' : 'border-[rgba(0,0,0,0.1)]'} 
      backdrop-blur-md transition-colors duration-200
    `}>
      <div className="logo-container flex items-center w-1/4">
        <LogoHeader />
      </div>
      <div className="search-container flex justify-center items-center w-1/2">
        {!isHomePage && !isPhone && (
          <div className="w-full max-w-[40rem]">
            <SearchContainer />
          </div>
        )}
      </div>
      <div className="wallet-container flex items-center justify-end space-x-4 w-1/4">
        <button
          onClick={toggleDarkMode}
          className={`
            p-2 rounded-full 
            ${isDarkMode ? 'bg-gray-700 text-gray-200 hover:bg-gray-600' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'} 
            transition-colors duration-200
          `}
        >
          {isDarkMode ? <FaSun /> : <FaMoon />}
        </button>
        <CustomTonConnectButton />
      </div>
    </header>
  );
}

export default Header;
