import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Post from '../../utils/post/Post';
import { useTheme } from '../../contexts/ThemeContext';
import { API_KEY } from '../../constants';

const BrowseAll = () => {
    const [posts, setPosts] = useState([]);
    const [activeTab, setActiveTab] = useState('recent');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { isDarkMode } = useTheme();
    const location = useLocation();

    const fetchPosts = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            let url = `${process.env.REACT_APP_API_URL}/posts`;
            if (activeTab === 'trending') {
                url += '?sort=votes';
            }
            const response = await fetch(url, {
                headers: {
                    'X-API-Key': API_KEY
                }
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setPosts(Array.isArray(data) ? data : []);
        } catch (error) {
            console.error('Error fetching posts:', error);
            setError('Failed to fetch posts. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    }, [activeTab]);

    useEffect(() => {
        fetchPosts();
    }, [fetchPosts, location.pathname]);

    const handleVote = useCallback(async (postId, voteData) => {
        // Update the local state to reflect the vote
        setPosts(prevPosts =>
            prevPosts.map(post =>
                post._id === postId
                    ? { ...post, upvotes: voteData.upvotes, downvotes: voteData.downvotes }
                    : post
            )
        );
    }, []);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="container mx-auto p-4">
            <div className="flex mb-4">
                <button
                    className={`mr-2 px-4 py-2 rounded ${activeTab === 'recent' ? 'bg-blue-600 text-white' : isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-200 text-black'}`}
                    onClick={() => setActiveTab('recent')}
                >
                    Recent
                </button>
                <button
                    className={`px-4 py-2 rounded ${activeTab === 'trending' ? 'bg-blue-600 text-white' : isDarkMode ? 'bg-gray-700 text-white' : 'bg-gray-200 text-black'}`}
                    onClick={() => setActiveTab('trending')}
                >
                    Trending
                </button>
            </div>
            {posts.length > 0 ? (
                posts.map(post => (
                    <Link key={post._id} to={`/post/${post._id}`} className="block mb-4">
                        <Post
                            post={post} // Pass the full post object
                            showTokenLink={true}
                            onVote={handleVote}
                        />
                    </Link>
                ))
            ) : (
                <div>No posts found.</div>
            )}
        </div>
    );
};

export default BrowseAll;
